<template>
  <div>
    <div class="flex justify-between py-2">
      <a-dropdown-button @click="open_add">
        {{ $t('add') }}
      </a-dropdown-button>
    </div>
    <custom-tabular
        ref="refCustomTabular"
        :column_defs="column_defs_create_org"
        :end_point="'organizations/pages_create_user_list/'"
        @open_drawer="open_drawer"/>
<!--    <setting-up-the-speakers ref="refSettingUpTheSpeakers" :column_defs="column_defs" :pages_front="con_fig" @updated_table="updated_table"/>-->
  </div>
</template>

<script>
import Card from "@/crm_components/card/Card.vue";
import FormSelect from "@/components/Forms/FormAddClient/FormSelect.vue";
import CustomTabular from "@/components/CustomTabular/CustomTabular.vue";
import SettingUpTheSpeakers from "@/components/SettingIpTheSpeakers/SettingUpTheSpeakers.vue";
import OpenDrawerRequestMixin from "@/pages/AddClient/RequestMixins/OpenDrawerRequestMixin.vue";
import {mapGetters} from "vuex";
import ProfileSelectView from "@/components/AntSelect/ProfileSelectView.vue";
import {EventBus} from "@/configs/eventBus";
export default {
  name: "CreateUsers",
  components: {
    SettingUpTheSpeakers,
    CustomTabular,
    Card,
    FormSelect,
  },

  props:{

  },
  async mounted() {
  },
  beforeDestroy(){
  },
  computed:{
    ...mapGetters({
      GET_PROFILE_COLUMN_DEFS:'user/GET_PROFILE_COLUMN_DEFS',
      GET_PAGES_FRONT:'user/GET_PAGES_FRONT',
    }),
  },
  mixins:[
    OpenDrawerRequestMixin
  ],
  data(){
    return {
      column_defs_create_org:[
        {
          headerName: this.$t('full_name'),
          title: this.$t('full_name'),
          field: 'full_name',
          sortable: true,
          filter: true,
          width: 200,
          use: true,
        },
        {
          headerName: this.$t('roles'),
          field: 'str_names_roles',
          title: 'str_names_roles',
          sortable: true,
          filter: true,
          width: 200,
          use: true,
        },
        {
          headerName: this.$t('organization_request'),
          field: 'str_names_organization_request',
          title: 'str_names_organization_request',
          sortable: true,
          filter: true,
          width: 200,
          use: true,
        },
      ],
    }
  },
  methods:{
    open_add(){
      EventBus.$emit('open_form_create_user');
      console.log('222addsadasddsasadds')
    }
  },
}
</script>

<style lang="scss">
@import "../../../../scss/base/variables";
.default-button{
  width: 200px;
  border-radius: $border-radius-base;
}
.save-button{

}
</style>
